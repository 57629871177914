import * as device from 'react-device-detect';
import {BrowserHistory, createBrowserHistory} from 'history';
import Environment from "./interfaces/Environment";

class DeviceService {

    readonly browserHistory: BrowserHistory;

    // TODO: Separate some functions from class
    // TODO: Remove parameter
    constructor(private env: Environment) {
        this.browserHistory = createBrowserHistory();
    }

    public isBrowser(): boolean {
        return device.isBrowser;
    }

    public isMobile(): boolean {
        return device.isMobile;
    }

    public isCrawler(): boolean {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
    }

    public isMobileWidth(): boolean {
        return (device.isBrowser || device.isMobile) && window.innerWidth <= 960;
    }

    public hasView(): boolean {
        return window.innerWidth > 0 && window.innerHeight > 0;
    }

    public prefersDarkTheme(): boolean {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    public setAttribute(key: string, value: any): void {
        document.documentElement.setAttribute(key, value);
    }

    public getFromLocalStorage(key: string): string | undefined {
        return localStorage.getItem(key) || undefined;
    }

    public setInLocalStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public goToTop(): void {
        return window.scrollTo(0, 0);
    }

    public goToBottom(): void {
        return window.scrollTo(0, window.outerHeight);
    }

    public getBrowserUrl(): string {
        if (device.isBrowser || device.isMobile) {
            return document.location.href;
        }

        return '';
    }

    public setBrowserUrl(url: string): void {
        if (device.isBrowser || device.isMobile) {
            this.browserHistory.push(url, '');
        }
    }

    public setBrowserSearchParam(key: string, value: string): void {
        if (device.isBrowser || device.isMobile) {
            let params: URLSearchParams = new URLSearchParams(document.location.search);
            params.set(key, value);
            this.browserHistory.replace({pathname: document.location.pathname, search: params.toString()}, '');
        }
    }
}

export default DeviceService;
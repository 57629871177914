import React, {Dispatch, useMemo, useState} from "react";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import PageActions from "../../../../base/PageActions";
import ContentBox from "../../../../../components/content-box/ContentBox";
import SarCharacterData, {_base_character_list} from "../../../../../model/SarCharacterData";
import "../../../../styles/Common.scss";
import "./SarCharacterStreamPage.scss";

const _chase_animals: string[] = [
    'Super Jaguar',
    'Super Bengal Tiger',
    'Super Mango Red Panda',
    'Super Painted Bat',
    'Super Orange Crocodile',
    'Super Orange Dragon',
    'Super Orange Salamander',
    'Super Orange Chicken',
    'Super Alebrije Owl',
    'Super Goldfish',
    'Super Orange Gummy Bear'
];

interface SarCharacterStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface SarCharacterStreamPageState {
    characters: SarCharacterData[];
}

const SarCharacterStreamPage = (props: SarCharacterStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `SAR Characters - Chase the Fox`,
        description: "See Chase's cast of redeemable SAR characters! You can switch to one during a stream!",
        keywords: ["twitch", "characters", "chasetheredfox", "super animal royale", "gaming"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/sar/characters`
    }), [props]);

    const [state]: [SarCharacterStreamPageState, Dispatch<SarCharacterStreamPageState>] = useState({
        characters: _base_character_list
    });

    return page.generateMetaContent(
        <>
            <ContentBox header="Redeemable Characters">
                <div>
                    <p className="center">
                        In exchange for channel points, I will switch to any these characters!
                    </p>
                    {state.characters.map(character =>
                        (<>
                            <hr/>
                            <div className="sar-character-content">
                                <img src={page.image().getCdnImageSrc(`chase/stream/sar/characters/${character.shortName}.png`)}
                                     alt={character.altText}
                                     title={character.fullName}/>
                                <p className="sar-character-name">{character.fullName}</p>
                                <p className="sar-character-description">
                                    {character.description}
                                </p>
                            </div>
                            {character.shortName === 'chase' && <>
                                <div className="center">
                                    <p>The following Super Animals are redeemable alts for Chase:</p>
                                    <div className="sar-chase-breed-content">
                                        {_chase_animals.map(breed =>
                                            <img src={page.image().getCdnImageSrc(
                                                `chase/stream/sar/chase/${breed.toLowerCase().replaceAll(' ', '_')}.png`
                                            )}
                                                 alt={breed}
                                                 title={breed}/>
                                        )}
                                    </div>
                                </div>
                            </>}
                        </>)
                    )}
                </div>
            </ContentBox>
        </>
    );
}

export default SarCharacterStreamPage;
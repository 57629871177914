import React, {useMemo} from "react";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import PageActions from "../../../base/PageActions";
import ContentBox from "../../../../components/content-box/ContentBox";
import '../../../styles/Common.scss';
import './ContactPage.scss';

interface ContactPageProps {
    dependencies: Dependencies,
    env: Environment
}

const ContactPage = (props: ContactPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Contact - Chase the Fox`,
        description: "Contact Chase over email or drop him a note on social media!",
        keywords: ["contact", "contact info", "contact information", "email", "social links"],
        imgSrc: `chase/assets/chase_full.png`,
        url: `contact`
    }), [props]);

    return page.generateMetaContent(
        <ContentBox className="contact-content" header="Chase the Fox">
            <h4 className="center">Website administrator, designer, coder, and artist</h4>
            <table className="contact-table">
                <tbody>
                <tr>
                    <td>
                        <img className="contact-image"
                             src={page.image().getCdnImageSrc(`common/social/email_icon.png`)}
                             alt="Email"/>
                    </td>
                    <td>chasereynard [[at]] gmail.com</td>
                </tr>
                <tr>
                    <td>
                        <a href="https://bsky.app/profile/chasetheredfox.bsky.social" target="_blank" rel="noopener noreferrer">
                            <img
                                className="contact-image"
                                src={page.image().getCdnImageSrc(`common/social/bluesky_logo.png`)}
                                alt="Bluesky"/>
                        </a>
                    </td>
                    <td><a href="https://bsky.app/profile/chasetheredfox.bsky.social"
                           target="_blank"
                           rel="noopener noreferrer">chasetheredfox</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="https://www.furaffinity.net/user/chasereynard" target="_blank" rel="noopener noreferrer">
                            <img
                                className="contact-image"
                                src={page.image().getCdnImageSrc(`common/social/fa_logo.png`)}
                                alt="FurAffinity"/>
                        </a>
                    </td>
                    <td><a href="https://www.furaffinity.net/user/chasereynard"
                           target="_blank"
                           rel="noopener noreferrer">ChaseReynard</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href="https://deviantart.com/ChaseReynard" target="_blank" rel="noopener noreferrer">
                            <img
                                className="contact-image"
                                src={page.image().getCdnImageSrc(`common/social/da_logo.png`)}
                                alt="DeviantArt"/>
                        </a>
                    </td>
                    <td><a href="https://deviantart.com/ChaseReynard"
                           target="_blank"
                           rel="noopener noreferrer">ChaseReynard</a>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="contact-right">
                <img
                    className="character-pic"
                    src={page.image().getCdnImageSrc(`chase/assets/chase_full.png`)}
                    alt="Contact Chase"/>
            </div>
        </ContentBox>
    );
}

export default ContactPage;
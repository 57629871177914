import React, {useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import SpeechBubble from "../../../components/speech-bubble/SpeechBubble";
import Dependencies from "../../../utilities/Dependencies";
import Environment from "../../../services/interfaces/Environment";
import PageActions from "../../base/PageActions";
import ContentBox from "../../../components/content-box/ContentBox";
import '../../styles/Common.scss';
import './Home.scss';
import dependencies from "../../../utilities/Dependencies";

interface HomeProps {
    dependencies: Dependencies,
    env: Environment
}

const _section_suggestions = [
    <NavLink to="/comics">reading about my friends and I in my comic, Tale Chasers</NavLink>,
    <NavLink to="/characters">getting to know the cast</NavLink>,
    <NavLink to="/world">learning about the world I live in</NavLink>,
    <NavLink to="/tf-generator">getting a transformational makeover</NavLink>,
    <NavLink to="/fursona-generator">creating a random character</NavLink>,
    <NavLink to="/stream">checking out my Twitch channel</NavLink>,
];

const Home = (props: HomeProps) => {

    const _could_not_get_status: string = 'Could not get status';

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Chase the Fox`,
        description: "The website for Chase the Fox, the shapeshifting orange fox from the world of Casarok!",
        keywords: ["social links", "site status"],
        imgSrc: `chase/assets/chase_full.png`,
        url: ""
    });

    const [fetchedResult, setFetchedResult] = useState<boolean>(false);

    const randomLink = useRef<JSX.Element>();
    const commissionStatus = useRef<string>();

    useEffect(() => {
        randomLink.current = _section_suggestions[Math.floor(Math.random() * _section_suggestions.length)];
        props.dependencies.httpService.getCommissionInfo()
            .then(response =>
                commissionStatus.current = (!response && Object.keys(response).length === 0)
                    ? _could_not_get_status
                    : response['status'])
            .catch(() => commissionStatus.current = _could_not_get_status)
            .finally(() => setFetchedResult(true));
    }, []);

    return page.generateMetaContent((
        <>
            <ContentBox className="site-intro">
                <SpeechBubble text="Hey, folks! Welcome welcome!" tail={page.device().isMobileWidth() ? 'middle' : 'right'}/>
                <img className="intro-wave"
                     src={page.image().getCdnImageSrc(`chase/assets/chase_full.png`)}
                     alt="Have fun!"
                     title="Hey, folks!"/>
                <p id="intro">
                    I'm Chase Reynard, the foxy sensation that's changin' the nation! I'm a red fox with a tendency
                    toward transformation. My home is in the world of Casarok where magic, advanced technology, and
                    other talking animals are the norm. Please stay for a while and check things out here! <br/>
                    <br/>
                    Have you considered {randomLink.current}?
                </p>
            </ContentBox>
            <div className="divider">
                <ContentBox className="news" header="News">
                    <NavLink to="comics/talechasers"
                             title="The Latest from Chase!">
                        <img src={props.dependencies.imageService.getCdnImageSrc(`chase/assets/latest_news.png`)}
                             alt="Tale Chasers Chapter 3 ongoing!"
                             title="Click here to go to the comic page!"/>
                    </NavLink>
                </ContentBox>
                <ContentBox className="status" header="Status">
                    <table>
                        <tbody>
                        <tr>
                            <td><strong>Commissions</strong></td>
                            <td><NavLink to="/extras/commissions">
                                {fetchedResult ? commissionStatus.current : 'Checking...'}
                            </NavLink></td>
                        </tr>
                        <tr>
                            <td><strong>Stream</strong></td>
                            <td><a href="https://twitch.tv/chasetheredfox/schedule">Schedule</a></td>
                        </tr>
                        <tr>
                            <td><strong>Tale Chasers</strong></td>
                            <td><NavLink to="comics/talechasers/3/1">Chapter 3 is live!</NavLink></td>
                        </tr>
                        </tbody>
                    </table>
                </ContentBox>
            </div>
        </>
    ));
}

export default Home;
import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import MediaButton from "../../../components/social-media/MediaButton";
import Dependencies from "../../../utilities/Dependencies";
import Environment from "../../../services/interfaces/Environment";
import PageActions from "../../base/PageActions";
import ContentBox from "../../../components/content-box/ContentBox";
import '../../styles/Common.scss';
import './QrCodeLandingPage.scss';

interface QrCodeLandingPageProps {
    dependencies: Dependencies,
    env: Environment
}

const QrCodeLandingPage = (props: QrCodeLandingPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `QR Landing - Chase the Fox`,
        description: "It looks like you scanned my QR code! Here's my information!",
        keywords: ["social links", "qr code"],
        imgSrc: `chase/assets/chase_full.png`,
        url: `qr`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox>
                <p className="qr-intro">
                    Pleasure to meet you! Check out my socials below or use the menu above to navigate my site.
                    Just... <NavLink to="/tf-generator/result?quick=true" target="_blank" rel="noreferrer noopener">
                        don't tap here, ok?
                    </NavLink>
                </p>
                <ul className="qr-media-buttons">
                    <li><MediaButton type="Telegram" size="small" username="ChaseTheFox"/></li>
                    <li><MediaButton type="Mastodon" size="small" code="furry.engineer" username="chasetheredfox"/></li>
                    <li><MediaButton type="Bluesky" size="small" code="bsky.social" username="chasetheredfox"/></li>
                    <li><MediaButton type="Twitch" size="small" username="ChaseTheRedFox"/></li>
                    <li><MediaButton type="YouTube" size="small" username="chasetheredfoxttv"/></li>
                    <li><MediaButton type="Fur Affinity" size="small" username="chasereynard"/></li>
                    <li><MediaButton type="Deviant Art" size="small" username="ChaseReynard"/></li>
                </ul>
            </ContentBox>
        </>
    );
}

export default QrCodeLandingPage;
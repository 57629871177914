import React from "react";
import {NavLink} from "react-router-dom";
import SiteTheme from "../../model/SiteTheme";
import DeviceService from "../../services/DeviceService";
import './Footer.scss';
import MediaButton from "../social-media/MediaButton";

interface FooterProps {
    theme: SiteTheme,
    window: DeviceService,
    themeUpdater?: (path: string) => void
}

const Footer = (props: FooterProps) => {

    return (
        <>
            <div id="footer">
                <div id="footer-media-buttons">
                    <ul>
                        <li><MediaButton type="Bluesky" size="mini" code="bsky.social" username="chasetheredfox"/></li>
                        <li><MediaButton type="Mastodon" size="mini" code="furry.engineer" username="chasetheredfox"/>
                        </li>
                        <li><MediaButton type="Twitch" size="mini" username="chasetheredfox"/></li>
                        <li><MediaButton type="YouTube" size="mini" username="chasetheredfoxttv"/></li>
                        <li><MediaButton type="Fur Affinity" size="mini" username="chasereynard"/></li>
                        <li><MediaButton type="Deviant Art" size="mini" username="ChaseReynard"/></li>
                    </ul>
                </div>
                <div id="footer-top-button">
                    <button className="top-button" onClick={() => props.window.goToTop()}>Return to Top</button>
                </div>
                <div id="footer-credits">
                    Character, concepts, art and site coding
                    by <NavLink to="/contact" target="_blank"
                                rel="noopener noreferrer">Chase the Fox</NavLink><br/>
                    &copy; 2015-2024 Chase the Fox
                </div>
            </div>
        </>
    );
}

export default Footer;